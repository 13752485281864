<template>
  <div class="container-wrapper">
    <div class="content-wrapper">
      <div class="table-wrapper">
        <table class="basicTable table-hover" align="center">
          <thead>
            <tr>
              <th style="width: 15px;">
                <input type="checkbox" :checked="isAllSelected" @change="toggleSelectAll" />
              </th>
              <th v-for="(column, index) in columns" :key="index" :style="{ width: column.width }">
                <span v-if="column.title === '배터리 잔량'" class="batteryToggle">
                  {{ column.title }}
                  <button v-if="!isAscending" @click="toggleSortOrder">▲</button>
                  <button v-else @click="toggleSortOrder" style="color:#F93C65 ">▼</button>
                  <button @click="resetToOriginalOrder"> <i class="fa fa-undo"></i></button>
                </span>
                <span v-else>
                  {{ column.title }}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in paginatedItems" :key="item.id" @click="handleRowClick(item, index)"
              :class="{ selected: selectedRowIndex === index }">
              <td>
                <input type="checkbox" :value="item[keyField]" v-model="selectedItems" @change="handleCheckboxChange"
                  @click.stop />
              </td>
              <template v-for="(field, fieldIndex) in fields" :key="fieldIndex">
                <!-- 순번을 표시하는 부분 -->
                <td v-if="field === 'index'">{{ index + 1 }}</td>

                <!-- 설치 위치를 표시하는 부분 -->
                <td v-else-if="field === 'installPosition'">
                  {{ String.fromCharCode(65 + item.installY) }}-{{ item.installX }}
                </td>

                <!-- 나머지 필드들 처리 -->
                <td v-else
                  :style="field === 'status' ? colorStatus(item.status) : field === 'batteryLevel' ? levelColor(item.batteryLevel) : {}">
                  <template v-if="field === 'affiliatedSeats'">
                    {{ item[field] ? item[field] : '-' }}
                  </template>
                  <template v-else-if="field === 'status'">
                    {{ resultLabel(item.status) }}
                  </template>
                  <template v-else-if="field === 'batteryLevel'">
                    {{ item.batteryLevel }}%
                  </template>
                  <template v-else>
                    {{ formatValue(item[field], field) }}
                  </template>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>


      <div v-if="isDetailsVisible" class="overlay" @click="hideDetails"></div>
      <!-- 상세 정보 패널 -->
      <div v-if="isDetailsVisible">
        <div :class="['detailsInfo', { open: isDetailsVisible }]">
          <button class="close-btn" @click="hideDetails">X</button>

          <div v-if="clickedData">
            <!-- 주차장 소유자 관리 -->
            <div v-if="activePanel === 'clickedInfo3'" class="clickedInfo3">

              <table class="panel-table">
                <thead>
                  <tr>
                    <th>주차장 이름</th>
                    <td>
                      <select v-model="selectedParking" @change="updateParkingDetails" class="ownerSelect">
                        <!-- 주차장 이름을 표시하기 위해 'name'을 사용 -->
                        <option v-for="parking in filteredParkingList" :key="parking.id" :value="parking.id">
                          {{ parking.name }}
                        </option>
                      </select>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>주차장 주소</th>
                    <td>{{ clickedData.parkingAddress }}</td>
                  </tr>
                  <tr>
                    <th>공유 주차면</th>
                    <td>{{ clickedData.deviceCount }}</td>
                  </tr>
                  <tr>
                    <th>소유자 이름</th>
                    <td>{{ clickedData.ownerName }}</td>
                  </tr>
                  <tr>
                    <th>소유자 연락처</th>
                    <td>{{ clickedData.phoneNumber }}</td>
                  </tr>
                  <tr>
                    <th>상호명</th>
                    <td>{{ clickedData.businessName }}</td>
                  </tr>
                  <tr>
                    <th>사업자 등록 번호</th>
                    <td>{{ clickedData.businessRegistrationNumber }}</td>
                  </tr>
                  <tr>
                    <th>정산은행</th>
                    <td>{{ clickedData.settlementBank }}</td>
                  </tr>
                  <tr>
                    <th>정산계좌</th>
                    <td>{{ clickedData.settlementAccount }}</td>
                  </tr>
                  <tr>
                    <th>계약일</th>
                    <td>{{ clickedData.contractStartDate }}</td>
                  </tr>
                  <tr>
                    <th>계약 만료일</th>
                    <td>{{ clickedData.contractEndDate }}</td>
                  </tr>
                  <tr>
                    <th>AS 만료일</th>
                    <td>{{ clickedData.asExpireDate }}</td>
                  </tr>
                  <!-- <tr>
                    <th>계약서</th>
                    <td>
                      <a :href="clickedData.contractFileLink" :download="clickedData.contractFileLink || 'contract.hwp'"
                        target="_blank">
                        계약서 보기
                      </a>
                    </td>
                  </tr> -->

                </tbody>
              </table>

              <!-- 주차장 소유자 관리 삭제버튼 -->
              <div v-if="activePanel === 'clickedInfo3'" class="button-container">
                <button class="red-btn" @click="모달창열렸니 = true">삭제</button>
              </div>
            </div>

            <!-- 주차장 기본 정보 -->
            <div v-else-if="activePanel === 'clickedInfo'" class="clickedInfo">
              <p><strong>주차장 이름</strong></p>
              <input type="text" v-model="clickedData.name" class="clickedInput" />
              <p><strong>주차장 주소</strong></p>
              <input type="text" v-model="clickedData.address" class="clickedInput" />
              <p><strong>기본시간</strong></p>
              <input type="text" v-model="clickedData.firstMinute" class="clickedInput " />
              <p><strong>기본요금</strong></p>
              <input type="text" v-model="clickedData.firstFee" class="clickedInput " />
              <p><strong>추가단위(분)</strong></p>
              <input type="text" v-model="clickedData.extraMinute" @input="setExtraFeeIfZero" class="clickedInput" />
              <p><strong>추가요금</strong></p>
              <input type="text" v-model="clickedData.extraFee" :disabled="isDisabled" class="clickedInput" />
              <p><strong>당일 최대 요금</strong></p>
              <input type="text" v-model="clickedData.limitFeeOfDay" :disabled="isDisabled" class="clickedInput" />
              <p><strong>운영 시작 시간</strong></p>
              <div class="halfInput">
                <input type="number" v-model="clickedData.operatingHour" class="clickedInput" />
                <p style="margin-right: 1rem;">시</p>
                <input type="number" v-model="clickedData.operatingMinute" class="clickedInput" />
                <p>분</p>
              </div>
              <p><strong>운영 종료 시간</strong></p>
              <div class="halfInput">
                <input type="number" v-model="clickedData.closingHour" class="clickedInput" />
                <p style="margin-right: 1rem;">시</p>
                <input type="number" v-model="clickedData.closingMinute" class="clickedInput" />
                <p>분</p>
              </div>
              <p><strong>가맹 주차면 수</strong></p>
              <input type="text" v-model="clickedData.affiliatedSeats" :disabled="true" class="clickedInput" />

              <div class="btnSection">
                <button class="blue-btn" style="height: 40px;" @click="openAffPopup">가맹 주차면 관리</button>
              </div>

              <!-- 수정 및 삭제 버튼 -->
              <div class="changeInfo">
                <button class="blue-btn changeBtn" @click="수정모달창열렸니 = true">수정</button>
                <button class="red-btn deleteBtn" @click="모달창열렸니 = true">삭제</button>
              </div>
            </div>

            <!-- 한시적 주차장 -->
            <div v-else-if="activePanel === 'clickedInfo2'" class="clickedInfo2">
              <p><strong>주차장 주소</strong></p>
              <div class="clickedInput" style="border: #6c757d solid 1px;">{{ clickedData.address }}</div>
              <p><strong>주차장 구간</strong></p>
              <div class="clickedInput" style="border: #6c757d solid 1px;">{{ clickedData.name }}</div>
              <p><strong>허용 시작 시간</strong></p>
              <div class="halfInput">
                <div class="clickedInput" style="border: #6c757d solid 1px;">{{ clickedData.startHour }}</div>
                <p style="margin-right: 1rem;">시</p>
                <div class="clickedInput" style="border: #6c757d solid 1px;">{{ clickedData.startMinute }}</div>
                <p>분</p>
              </div>
              <p><strong>허용 종료 시간</strong></p>
              <div class="halfInput">
                <div class="clickedInput" style="border: #6c757d solid 1px;">{{ clickedData.endHour }}</div>
                <p style="margin-right: 1rem;">시</p>
                <div class="clickedInput" style="border: #6c757d solid 1px;">{{ clickedData.endMinute }}</div>
                <p>분</p>
              </div>
              <p><strong>주차장 설명</strong></p>
              <div class="clickedInput" style="border: #6c757d solid 1px;">{{ clickedData.description }}</div>
              <p><strong>구간</strong></p>
              <div class="clickedInput" style="border: #6c757d solid 1px;">{{ clickedData.distance }}</div>


              <!-- 수정 및 삭제 버튼 -->
              <div class="changeInfo">

                <button class="red-btn deleteBtn mt-1" @click="모달창열렸니 = true">삭제</button>
              </div>
            </div>


            <!-- 주차장 기기 리스트 -->
            <div v-else-if="activePanel === 'clickedInfo4'" class="clickedInfo4">
              <table class="panel-table">
                <thead>
                  <tr>
                    <th>주차장 이름</th>
                    <td>
                      {{ clickedData.parkingName }}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>MAC Address</th>
                    <td>{{ clickedData.macaddress }}</td>
                  </tr>
                  <tr>
                    <th>기기 ID</th>
                    <td>{{ clickedData.deviceName }}</td>
                  </tr>
                  <tr>
                    <th>설치 위치</th>
                    <td>{{ String.fromCharCode(65 + clickedData.installY) }}-{{ clickedData.installX }}</td>
                  </tr>
                  <tr>
                    <th>시작 시간</th>
                    <td>{{ clickedData.availableStartTime }}</td>
                  </tr>
                  <tr>
                    <th>종료 시간</th>
                    <td>{{ clickedData.availableEndTime }}</td>
                  </tr>
                  <tr>
                    <th>배터리 잔량</th>
                    <td :style="levelColor(clickedData.batteryLevel)">{{ clickedData.batteryLevel }}%</td>
                  </tr>
                  <tr>
                    <th>주차장 내 위치</th>
                    <td>{{ clickedData.contractStartDate }}</td>
                  </tr>
                </tbody>
              </table>

              <div v-if="activePanel === 'clickedInfo4'" class="button-container">
                <button class="red-btn" @click="모달창열렸니 = true">삭제</button>
              </div>

            </div>


          </div>
          <p v-else>데이터가 없습니다.</p>
        </div>


        <!-- 삭제 모달 -->
        <b-modal v-model="모달창열렸니" title="삭제 확인" hide-footer hide-header centered>
          <div class="modalContents">
            <div class="modalImageWrapper">
              <img src="@/assets/icons/Vector.svg" alt="" class="modalCar">
            </div>
            <div class="modalTextWrapper">
              <p class="mainAnswer">주차장을 삭제하시겠습니까?</p>
            </div>

            <div class="modalBtn">
              <b-button class="modal-blue-btn" @click="deleteItem">네</b-button>
              <b-button class="modal-red-btn" @click="모달창열렸니 = false">아니오</b-button>
            </div>
          </div>
        </b-modal>

        <!-- 수정 모달 -->
        <b-modal v-model="수정모달창열렸니" title="삭제 확인" hide-footer hide-header centered>
          <div class="modalContents">
            <div class="modalImageWrapper">
              <img src="@/assets/icons/Vector.svg" alt="" class="modalCar">
            </div>
            <div class="modalTextWrapper">
              <p class="mainAnswer">주차장 정보를 수정하시겠습니까?</p>
            </div>
            <div class="modalBtn">
              <b-button class="modal-blue-btn" @click="updateItem">네</b-button>
              <b-button class="modal-red-btn" @click="수정모달창열렸니 = false">아니오</b-button>
            </div>
          </div>
        </b-modal>

        <!-- 주차 도면 및 기기 관리 팝업 -->
        <div v-if="isAffiliationPopupVisible" class="modal-overlay">
          <div class="devicePopup">
            <div class="popupText">
              <button class="popup-close-btn" @click="isAffiliationPopupVisible = false">X</button>
              <p>해당 주차장의 가맹 주차면 수를 적어주세요</p>
              <div class="popupInput mt-4 my-3">
                <div class=" ">
                  <span>가맹 주차면 수</span>
                  <input 
                    class="popup px-2" 
                    type="number" 
                    v-model="affiliationSeats" 
                    pattern="[0-9]*"
                  />
                </div>
              </div>
              <div v-if="isAffCntError" class="AffCntError">
                <p>1 이상의 숫자만 입력해주세요.</p>
              </div>
            </div>
            <div class="popupBtn">
              <button class="modal-blue-btn" @click="closeAffPopup">등록</button>
              <button 
              :class="{
                'modal-red-btn': clickedData.affiliatedSeats != null,
                'modal-inactive-btn':  clickedData.affiliatedSeats == null
                }"
              @click="handleAffDeleteAction">가맹 해지</button>
            </div>
          </div>
        </div>

        <!-- 재확인 모달 -->
        <b-modal v-model="showDeleteConfirmationModal" hide-footer hide-header centered>
            <div class="modalContents">
                <div class="modalImageWrapper">
                    <img src="@/assets/icons/Vector.svg" alt="" class="modalCar" />
                </div>
                <div class="modalTextWrapper">
                    <p class="mainAnswer">해당 주차장의 가맹을 해지하시겠습니까?</p>
                </div>
                <div class="modalBtn">
                    <!-- "네" 버튼 클릭 시 createAnswer 메서드 호출 -->
                    <b-button class="modal-blue-btn" @click="deleteAffiliation">네</b-button>
                    <b-button class="modal-red-btn" @click="showDeleteConfirmationModal = false">아니오</b-button>
                </div>
            </div>
        </b-modal>
      </div>

    </div>
  </div>
  <b-pagination class="tablePage" :per-page="perPage" :current-page="currentPage" :total-rows="localItems.length"
    v-model="currentPage" align="center" aria-controls="my-table"></b-pagination>

</template>


<script>
import Swal from 'sweetalert2';
import { mapActions } from 'vuex';

export default {

  name: "basicTable",
  emits: ['rowClick', 'update-selected-items', 'change', 'update-items'],
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    columns: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    keyField: {  // 추가한 부분
      type: String,
      required: true,
    },
    originalItems: {  // 기기리스트에서 넘겨받은 originalItems를 props로 받음
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      localItems: [],
      currentPage: 1,
      perPage: 20,
      clickedData: null,
      originalExtraFee: null,
      isDetailsVisible: false,
      selectedRowIndex: null,
      activeButton: null,
      모달창열렸니: false,
      수정모달창열렸니: false,
      devicePopup: false,
      isAffiliationPopupVisible: false,
      affiliationSeats: null,
      isAffCntError:false,
      showDeleteConfirmationModal: false,
      activePanel: null,
      selectedItems: [],
      selectedParking: null,
      horizontalCount: null,
      verticalCount: null,
      isAscending: true,
      isDisabled: false,
    };
  },
  computed: {
    paginatedItems() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.localItems.slice(start, end);
    },
    // 체크박스 전체 선택//
    isAllSelected() {
      return this.paginatedItems.length > 0 && this.paginatedItems.every(item => this.selectedItems.includes(item[this.keyField]));
    },
    // row클릭시 필터리스트에 데이터 담김 //
    filteredParkingList() {
      if (this.clickedData && this.clickedData.parkingInfoList) {
        console.log("주차장 목록:", this.clickedData.parkingInfoList); 

        // 각 항목의 name을 출력
        this.clickedData.parkingInfoList.forEach(parking => {
          console.log("주차장 이름:", parking.name);
        });

        return this.clickedData.parkingInfoList; // 
      }
      return [];
    },
    // 체크박스 클릭시 필터리스트에 데이터 담김 //
    filteredSelectedItems() {
      if (this.selectedItems.length > 0) {
        const filteredList = this.items.filter(item =>
          this.selectedItems.includes(item[this.keyField])
        );
        console.log("Filtered Selected Items: ", filteredList);
        this.$emit('update-selected-items', filteredList);
        return filteredList;
      }
      return [];
    },
  },
  watch: {
    paginatedItems(newItems) {
      // 현재 페이지에 데이터가 없으면 첫 페이지로 초기화
      if (newItems.length === 0 && this.currentPage > 1) {
        this.currentPage = 1;
      }
    },
    items: {
      immediate: true,
      handler(newItems) {
        this.localItems = this.splitTime(this.splitAddress(newItems));
      }
    },
    isDetailsVisible(newValue) {
      if (newValue && this.filteredParkingList.length > 0) {
        this.selectedParking = this.filteredParkingList[0].id;
        this.updateParkingDetails();
      }

    }
  }
  ,
  methods: {
    ...mapActions(['postAffSeats', 'postAffDelete']),
    splitAddress(items) {
      if (!Array.isArray(items)) {
        console.warn('splitAddress 함수로 전달된 items가 배열이 아닙니다:', items);
        return [];  // 배열이 아닌 경우 빈 배열 반환
      }
      return items.map(item => {
        if (!item.address) {
          return { ...item, region: '', city: '' };
        }
        const [region, city] = item.address.split(' ');
        return { ...item, region, city };
      });
    },
    // 시간 : 시분초 나누기
    splitTime(items) {
      return items.map(item => {
        const startTimeParts = item.startTime ? item.startTime.split(':') : ['', '', ''];
        const endTimeParts = item.endTime ? item.endTime.split(':') : ['', '', ''];
        const operatingTime = item.operatingTime ? item.operatingTime.split(':') : ['', '', ''];
        const closingTime = item.closingTime ? item.closingTime.split(':') : ['', '', ''];
        return {
          ...item,
          startHour: startTimeParts[0],
          startMinute: startTimeParts[1],
          startSecond: startTimeParts[2],
          endHour: endTimeParts[0],
          endMinute: endTimeParts[1],
          endSecond: endTimeParts[2],
          operatingHour: operatingTime[0],
          operatingMinute: operatingTime[1],
          operatingSecond: operatingTime[2],
          closingHour: closingTime[0],
          closingMinute: closingTime[1],
          closingSecond: closingTime[2],
        };
      });
    },
    // 행 클릭시 로직
    handleRowClick(item, index) {
      this.$emit('update-items', [...this.originalItems])
      this.$emit('rowClick', item, index);
      // 행 클릭하면 데이터가 clickData에 담김   
      this.clickedData = item;
      this.selectedRowIndex = index;
      // 타입에 따라 다른 종류의 패널 띄우기
      this.activePanel =
        item.type === 'type1' ? 'clickedInfo' :
          item.type === 'type2' ? 'clickedInfo2' :
            item.type === 'type3' ? 'clickedInfo3' :
              'clickedInfo4';
      // 행 클릭시 background-color
      this.isDetailsVisible = true;
      this.selectedParking = item.name
    },


    // [주차장 소유자 관리페이지]: 상세페널 내 주차장 이름 드롭다운
    updateParkingDetails() {
      // 선택한 주차장 ID에 해당하는 데이터를 filteredParkingList에서 찾음
      const selectedParkingData = this.filteredParkingList.find(
        (parking) => parking.id === this.selectedParking
      );

      if (selectedParkingData) {
        console.log('클릭 데이터 확인:', this.clickedData);
        // 선택한 주차장의 데이터를 clickedData에 반영
        this.clickedData.parkingAddress = selectedParkingData.parkingAddress; // 주차장 주소
        this.clickedData.deviceCount = selectedParkingData.deviceCount; // 제휴 주차면수
        this.clickedData.settlementBank = selectedParkingData.settlementBank; // 정산은행
        this.clickedData.settlementAccount = selectedParkingData.settlementAccount; // 정산 계좌
        this.clickedData.contractStartDate = selectedParkingData.contractStartDate;  // 계약일
        this.clickedData.contractEndDate = selectedParkingData.contractEndDate; // 계약 만료일
        this.clickedData.asExpireDate = selectedParkingData.asExpireDate;
        this.clickedData.id = selectedParkingData.id;
        this.clickedData.affiliatedSeats = selectedParkingData.affiliatedSeats;
        // 필요한 다른 필드들도 업데이트
      }

    },

    hideDetails() {
      this.isDetailsVisible = false;
      this.selectedRowIndex = null;
      this.activePanel = null;
    },

    // [체크박스] 전체 선택&해제

    toggleSelectAll(event) {
      if (event.target.checked) {
        // 현재 페이지의 항목들을 모두 선택
        const allItemKeys = this.paginatedItems.map(item => item[this.keyField]);
        this.selectedItems = [...new Set([...this.selectedItems, ...allItemKeys])];
      } else {
        // 현재 페이지의 항목들을 모두 선택 해제
        const allItemKeys = this.paginatedItems.map(item => item[this.keyField]);
        this.selectedItems = this.selectedItems.filter(key => !allItemKeys.includes(key));
      }
      this.handleCheckboxChange();
    },
    //[체크박스] filteredList(checkbox)에 담긴 데이터 부모 페이지로 보내기
    handleCheckboxChange() {
      const selectedData = this.filteredSelectedItems;
      this.$emit('update-selected-items', selectedData);
      console.log("체크박스 바뀜:", selectedData);
    },


    // 각 페이지에서 상세 패널 내 삭제기능
    deleteItem() {
      if (this.clickedData) {
        const isParkingList = this.clickedData.type === 'type1';
        const isTemporaryParking = this.clickedData.type === 'type2'; // 한시적 주차 
        const isOwnerParking = this.clickedData.type === 'type3'; // 소유자 관리

        if (isTemporaryParking) {
          // 한시적 주차 리스트
          this.$store.dispatch('deleteTempParking', this.clickedData.id)
            .then(() => {
              this.hideDetails();
              this.모달창열렸니 = false;
            })
            .catch(error => {
              console.error( error);
            });
        } else if (isOwnerParking) {
          // 소유자 관리
          console.log("삭제하려는 소유자", this.clickedData.ownerId);

          const ownerId = this.clickedData.ownerId;
          const id = this.clickedData.id;

          this.$store.dispatch('deleteOwnership', { ownerId, id })
            .then(() => {
              this.hideDetails();
              this.모달창열렸니 = false;
            })
            .catch(error => {
              console.error('Error deleting owner:', error);
            });
        } else if (isParkingList) {
          this.$store.dispatch('deleteParkinglist', this.clickedData.id)
            .then(() => {
              this.hideDetails();
              this.모달창열렸니 = false;
            })
            .catch(error => {
              console.error('Error deleting parking lot:', error);
            });
        } else {
          const deviceId = this.clickedData.deviceUID;

          console.log("삭제하려는 주차장(테이블):", deviceId);
          this.$store.dispatch('deleteDevicelist', deviceId)
            .then(() => {
              // originalItems의 복사본을 사용하여 수정
              this.localItems = this.localItems.filter(item => item.deviceUID !== deviceId);
              // 복사본을 업데이트한 후, 원래 상태에 반영
              this.$emit('update-items', this.localItems);


              this.hideDetails();
              this.모달창열렸니 = false;
            })
            .catch(error => {
              console.error( error);
            });
        }
      }
    },
    combineTimesForServer(data) {
      // 시, 분, 초 값을 두 자리 형식으로 맞추기
      const formatTime = (hour, minute, second) => {
        const formattedHour = String(hour || '00').padStart(2, '0');
        const formattedMinute = String(minute || '00').padStart(2, '0');
        const formattedSecond = String(second || '00').padStart(2, '0');
        return `${formattedHour}:${formattedMinute}:${formattedSecond}`;
      };

      return {
        ...data,
        operatingTime: formatTime(data.operatingHour, data.operatingMinute, data.operatingSecond),
        closingTime: formatTime(data.closingHour, data.closingMinute, data.closingSecond),
      };
    },

    updateItem() {
      // 데이터를 서버 형식으로 합침
      const dataToSend = this.combineTimesForServer(this.clickedData);

      if (this.clickedData) {
        // 서버에 전송할 데이터로 Vuex 액션 호출
        const isTemporaryParking = this.clickedData.type === 'type2'; // 한시적 주차 구분
        const updateAction = isTemporaryParking ? 'updateTempParking' : 'updateParkinglist';

        this.$store.dispatch(updateAction, dataToSend)
          .then((updatedData) => {
            this.$store.commit('UPDATE_PARKING_ITEM', updatedData); // Vuex 상태 업데이트
            this.수정모달창열렸니 = false;
            this.hideDetails();
          })
          .catch((error) => {
            console.error( error);

            // 오류 코드에 따른 세분화된 메시지 처리
            const errorCode = error.response?.data?.code;
            this.수정모달창열렸니 = false;

            if (errorCode === 80011) {
              Swal.fire({
                imageUrl: require('@/assets/icons/Vector.svg'),
                html: `<div style='font-size:1.5rem; margin-bottom: 1rem; font-weight:900;'>입력값을 확인하세요</div>
                   <div style='font-size:1rem; margin-top: 1rem;'> 일부 기기가 <span style="color: #F93C65;">운영 시간 범위</span>에 포함되지 않습니다
                     <br><span style='color:#F93C65;'>운영 시간</span>을 다시 확인해주세요</div>`,
                confirmButtonText: '확인',
                confirmButtonColor: '#5B67EC',
                imageWidth: 55,
                imageHeight: 55,
              });
            } else {
              Swal.fire({
                imageUrl: require('@/assets/icons/Vector.svg'),
                html: `<div style='font-size:1.5rem; margin-bottom: 1rem; font-weight:900;'>입력값을 확인하세요</div>
                   <div style='font-size:1rem; margin-top: 1rem;'>주차 항목 수정중 오류가 발생하였습니다</div>`,
                confirmButtonText: '확인',
                confirmButtonColor: '#5B67EC',
                imageWidth: 55,
                imageHeight: 55,
              });
            }
          });
      }
    },

    // [주차장 리스트] 상세 패널 기기있음 &기기없음
    // 클릭데이터의 상태값에 따라..
    toggleButtons(isInstalled) {
      this.clickedData.isInstalledDevice = isInstalled;
    },

    formatValue(value, field) {
      // 숫자 필드를 위한 콤마 처리
      if (this.isNumberField(field) && typeof value === 'number') {
        return new Intl.NumberFormat().format(value);
      }
      // 숫자가 아닌 경우 원래 값 반환
      return value !== undefined && value !== null ? value : '-';  // 값이 없을 경우 '-'로 표시
    },
    isNumberField(field) {
      // 숫자 필드를 지정하는 로직 (여기서는 firstFee, extraFee, deviceCount 등)
      const numberFields = ['firstFee', 'extraFee', 'deviceCount', 'extraMinute'];
      return numberFields.includes(field);
    },
    // 상태에 따른 색상 적용
    colorStatus(status) {
      const colorStatus = {
        WORKING: '#5B67EC',
        UNAVAILABLE: '#F93C65',
      };
      return { color: colorStatus[status] || 'black' };
    },
    resultLabel(status) {
      const statusMap = {
        WAITING: '대기중',
        WORKING: '주차중',
        RESERVED: '예약 완료',
        UNAVAILABLE: '이용 불가',
      };
      return statusMap[status] || '-';
    },

    levelColor(batteryLevel) {
      if (batteryLevel < 20) {
        return { color: '#F93C65' }
      } else if (batteryLevel < 60) {
        return { color: '#00B69B' };
      } else if (batteryLevel <= 100) {
        return { color: '#5B67EC' };
      }
      return { color: 'black' }; // 그 외의 경우 기본 색상
    },
    openAffPopup() {
      this.isAffiliationPopupVisible = true
      this.isAffCntError = false
      this.affiliationSeats = null
    },
    closeAffPopup() {
      if (parseInt(this.affiliationSeats, 10) > 0) {
        this.postAffSeatCnt();
        this.isAffiliationPopupVisible = false
        this.affiliationSeats = null
      } else {
        this.isAffCntError = true
      }
    },
    handleAffDeleteAction(){
      if (this.clickedData.affiliatedSeats != null) {
        this.showDeleteConfirmationModal = true
      }
    },
    async deleteAffiliation(){
      try {
        await this.postAffDelete({id: this.clickedData.id});
        this.showDeleteConfirmationModal = false
        this.isAffiliationPopupVisible = false
      } catch(error) {
        console.error('가맹 해지 중 오류 발생:', error);
      }
    },
    async postAffSeatCnt() {
    // Vuex 액션 호출
      const affData = {affiliatedSeat: parseInt(this.affiliationSeats, 10)}
      try {
        await this.postAffSeats({id: this.clickedData.id,  seatCnt: affData});
      } catch (error) {
        console.error('제휴면수 수정 중 오류 발생:', error);
      }
    },
    // 주차장도면관리 페이지 이동
    navigateToParkingLayout() {
      this.devicePopup = false; // 팝업을 닫음
      this.$router.push({
        path: '/parkingLayout',
        query: {
          horizontalCount: this.horizontalCount,
          verticalCount: this.verticalCount,
          clickedData: JSON.stringify(this.clickedData)
        }
      });
    },
    async handleDeviceManagement() {
      // 현재 선택된 데이터의 isInstalledDevice 값에 따라 로직 분기
      if (!this.clickedData.isInstalledDevice) {
        // 기기 설치가 안 된 경우, devicePopup을 열어줌
        this.devicePopup = true;
      } else {
        // 기기 설치가 된 경우, 도면 관리 페이지로 이동
        try {
          this.navigateToParkingLayout(); // navigateToParkingLayout 메소드를 호출하여 페이지 이동
        } catch (error) {
          console.error('페이지 이동 중 오류 발생:', error);
        }
      }
    },
    toggleSortOrder() {
      if (this.isAscending) {
        this.sortByBatteryLevel('asc');
        console.log("눌렸습니다")
      } else {
        this.sortByBatteryLevel('desc');
        console.log("눌렸습니다")
      }
      this.isAscending = !this.isAscending;
      console.log("정렬 후 localItems:", this.localItems);
    },
    sortByBatteryLevel(order) {
      if (order === 'asc') {
        this.localItems.sort((a, b) => a.batteryLevel - b.batteryLevel); // Ascending order
      } else if (order === 'desc') {
        this.localItems.sort((a, b) => b.batteryLevel - a.batteryLevel); // Descending order
      }
    },
    formatTime(time) {
      return time ? time.slice(0, 5) : ''; // 시간 형식을 HH:MM으로 변환
    },
    resetToOriginalOrder() {
      this.localItems = [...this.originalItems].sort((a, b) => {
        return a.parkingName.localeCompare(b.parkingName);
      });
      this.localItems = this.localItems.map(item => {
        return {
          ...item,
          availableStartTime: this.formatTime(item.availableStartTime),
          availableEndTime: this.formatTime(item.availableEndTime),
        };
      });
    },
    setExtraFeeIfZero() {
      if (this.clickedData.extraMinute === '0' || this.clickedData.extraMinute === 0) {
        if (this.originalExtraFee === null) {
          this.originalExtraFee = this.clickedData.extraFee;
        }
        this.clickedData.extraFee = 0;
        this.isDisabled = true;
      } else {
        if (this.originalExtraFee !== null) {
          this.clickedData.extraFee = this.originalExtraFee;
          this.originalExtraFee = null;
        }
        this.isDisabled = false;
      }
    },

  },
  created() {
    this.localItems = this.splitTime(this.splitAddress(this.items));

    // URL에서 clickedData 가져오기
    const clickedData = this.$route.query.clickedData;
    if (clickedData) {
      this.clickedData = JSON.parse(clickedData);

      // 클릭된 데이터에 해당하는 테이블의 행을 찾아 선택
      const index = this.localItems.findIndex(item => item.id === this.clickedData.id);
      if (index !== -1) {
        this.handleRowClick(this.localItems[index], index);  // 해당 행 선택 및 상세 패널 열기
      }
    }
    if (this.$route.query.devicePopup === 'true') {
      this.devicePopup = true;
    }
  }


};
</script>


<style>
.container-wrapper {
  display: flex;
  flex-direction: column;

}


.table-wrapper {
  flex: 1;
  display: flex;
  margin-bottom: 1rem;
  width: 100%;

}

.basicTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #6c757d !important;
  background-color: #ffffff;
  border-radius: 8px;
  max-height: 100%;
  table-layout: fixed;
}

.basicTable th,
.basicTable td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding: 0.5rem 1rem;
}

.panel-table {
  width: 100%;
}

/* 테이블 셀 패딩 조정 */

.panel-table th,
.panel-table td {
  padding: 1rem;
}

.basicTable thead th:first-child {
  border-top-left-radius: 1rem;
}

.basicTable thead th:last-child {
  border-top-right-radius: 1rem;
}

.basicTable thead {
  color: black;
  font-weight: 100;
}

.basicTable thead th {
  border-bottom: 2px solid #eaeaea;
}

.basicTable tbody tr td {
  border-bottom: 1px solid #eaeaea;
}

.basicTable tbody tr:last-child td {
  border-bottom: none;
}

.panel-table thead th {
  border-bottom: 2px solid #eaeaea;
}

.panel-table thead td {
  border-bottom: 2px solid #eaeaea;
}

.panel-table tbody tr th,
.panel-table tbody tr td {
  border-bottom: 1px solid #eaeaea;
}

.panel-table tbody tr:last-child th {
  border-bottom: none;
}

.panel-table tbody tr:last-child td {
  border-bottom: none;
}

.basicTable tbody tr:last-child td:first-child {
  border-bottom-left-radius: 1rem;
}

.basicTable tbody tr:last-child td:last-child {
  border-bottom-right-radius: 1rem;
}

.basicTable tbody tr.selected {
  background-color: #d4d7f5;
  /* 선택된 행의 배경색 */
}

.detailsInfo {
  position: fixed;
  top: 0;
  right: -450px;
  width: 450px;
  height: 100%;
  max-height: 100vh;
  background-color: #ffffff;
  transition: right 10s ease;
  z-index: 1000;
}

.detailsInfo.open {
  right: 0;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.close-btn:hover {
  color: red;
}

.installedButton {
  background-color: #ffffff;
  color: #000;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 6px 1.25rem;
}

.installedButton.active {
  background-color: #f0f0f0;
  color: black;
}

.changeInfo {
  width: 100%;
  justify-content: center;
  display: flex;
  gap: 0.6rem;
  margin-top: 0.5rem;
}

.changeInfo>button {
  width: 100%;
}

.clickedInfo p,
.clickedInfo input {
  margin: 5px 0;
}

.clickedInfo2 {
  padding: 2rem;
  margin-top: 4rem;
}

.clickedInfo3 {
  width: 100%;
  height: 100%;
}

.clickedInfo4 {
  margin-top: 4rem;
}

.clickedInput {
  margin-top: 0px;
  padding: 0.5rem;
  width: 100%;
}

.halfInput {
  display: flex;
  align-items: center;
}

.halfInput p {
  margin-left: 0.5rem;
}

.modalContents {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2.5rem;
  margin-top: 1.25rem;
  height: 280px;
}

.modalImageWrapper,
.modalTextWrapper {
  width: 100%;
  text-align: center;
  margin-top: 0.3rem;
}

.modalCar {
  display: block;
  margin: 0 auto;
  width: 3.5rem;
  height: 3.5rem;
}

.modalBtn {
  display: flex;
  gap: 0.625rem;
  background-color: white;
  border: #d9d9d9;
  width: 100%;
  height: 20%;
  margin-top: 1.25rem;
  align-items: center;
  justify-content: center;
}


.modal-blue-btn {
  width: 100% !important;
  height: auto;
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
  background-color: white !important;
  color: #5b67ec !important;
  border: 1px solid #5b67ec !important;
  border-radius: 1rem;
  transition: background-color 0.3s ease !important;
  text-align: center;
}

.modal-black-btn {
  width: 100% !important;
  height: auto;
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
  background-color: white !important;
  color: #6C757D !important;
  border: 1px solid #6C757D !important;
  border-radius: 1rem;
  transition: background-color 0.3s ease !important;
  text-align: center;
}

.modal-red-btn {
  width: 100% !important;
  height: auto;
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
  background-color: white !important;
  color: #F93c65 !important;
  border: 1px solid #F93c65 !important;
  border-radius: 1rem;
  transition: background-color 0.3s ease !important;
  text-align: center;
}

.modal-inactive-btn {
  width: 100% !important;
  height: auto;
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
  background-color: white !important;
  color: #6C757D !important;
  border: 1px solid #6C757D !important;
  border-radius: 1rem;
  transition: background-color 0.3s ease !important;
  text-align: center;
  cursor: default !important;
}

.modal-blue-btn:hover {
  background-color: #5b67ec !important;
  color: white !important;
}

.modal-blue-btn:active {
  background-color: #5b67ec !important;
  color: white !important;
}

.modal-black-btn:hover {
  background-color: #6C757D !important;
  color: white !important;
}

.modal-blak-btn:active {
  background-color: #6C757D !important;
  color: white !important;
}

.modal-red-btn:hover {
  background-color: #F93c65 !important;
  color: white !important;
}

.modal-red-btn:hover {
  background-color: #F93c65 !important;
  color: white !important;
}


.mainAnswer {
  font-size: 1.1rem;
  font-weight: 900;
  margin-bottom: 0.3rem;
}

.btnSection {
  display: flex;
  width: 100%;
  gap: 0.625rem;
  margin-top: 20px
}

.btnSection>button {
  width: 100%;
}

/* 페이지네이션 기본 스타일 제거 */
.tablePage .page-link {
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  border-color: transparent !important;
  color: inherit !important;
}

.tablePage .page-item.active .page-link {
  background-color: transparent !important;
  border-color: #5b67ec !important;
  border-radius: 0.625rem;
}

.tablePage .page-link:focus,
.tablePage .page-link:hover {
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}


.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 0.3rem;
  margin-top: 1.25rem;
}

.ownerSelect {
  width: 150px;
  height: 80%;
  border: 1px solid #DFDFDF;
  box-sizing: border-box;
  border-radius: 0.625rem;
  padding: 0.75rem 0.8rem;
}

/* 어두운 배경을 위한 overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* 투명한 어두운 배경 */
  z-index: 999;
}

.devicePopup {
  padding: 2rem !important;
  background-color: white !important;
  padding: 2rem;
  border-radius: 18px;
  width: 80%;
  max-width: 500px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-close-btn {
  align-items: end;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

.popupText {
  text-align: end;
}

.popupText>p {
  text-align: center;
  font-size: 18px;
  font-weight: bolder;
}

.popupText>span {
  font-size: 15px;
  color: #F93c65;
}

.AffCntError>p {
  color: #F93c65;
}

.textSpan {
  margin-top: 1rem;
}

.popupInput {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.popupInput>div {
  margin-bottom: 1rem;
  width: 100%;
}

.popup {
  margin-left: 2rem;
  border-radius: 10px;
  border: 1px solid #DFDFDF;
  width: auto;
  height: 50px;

}

.popupBtn {
  display: flex;
  gap: 10px;
}

.popupBtn>button {
  flex: 1;
}

.batteryToggle {
  display: flex;
}

.batteryToggle>button {
  color: #5b67ec;
  background: none;
  border: none;
  font-size: 1rem;

}
</style>