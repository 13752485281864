import axios from 'axios';
import store from './store/store';
import router from '@/router'; // Vue Router 사용

// Axios 기본 설정
// axios.defaults.baseURL = '/api';
axios.defaults.baseURL = 'https://api.simpleparking.co.kr';

// 요청 인터셉터: 토큰이 있으면 Authorization 헤더에 추가
axios.interceptors.request.use(
  (config) => {
    const token = store.state.token; // Vuex 상태에서 직접 가져오기∂
    if (config.url !== 'v1/auth') {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 응답 인터셉터: 토큰이 만료된 경우 재발급
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // 401 에러 처리
    if (error.response && error.response.status === 401) {
      if (!originalRequest._retry) {
        originalRequest._retry = true; // 재시도 플래그 설정

        try {
          // 리프레시 토큰으로 새로운 액세스 토큰 요청
          const refreshToken = store.state.refreshToken; // Vuex에서 리프레시 토큰 가져오기
          if (!refreshToken) {
            throw new Error('리프레시 토큰이 없습니다. 로그아웃 처리.');
          }

          const refreshResponse = await axios.put('v1/auth/reissue', {
            refreshToken: refreshToken,
          });

          const newAccessToken = refreshResponse.data.accessToken;

          // 새 토큰을 Vuex에 저장
          store.commit('setAccessToken', newAccessToken);

          // 기존 요청에 새 토큰 적용
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

          // 기존 요청 재시도
          return axios(originalRequest);
        } catch (refreshError) {
          console.error('토큰 갱신 실패:', refreshError);

          // 리프레시 토큰도 만료 -> 로그아웃 처리
          store.commit('logout'); // Vuex 상태 초기화
          router.push('/loginPage'); // 로그인 페이지로 이동
          return Promise.reject(refreshError);
        }
      }
    }

    // 다른 에러는 그대로 전달
    return Promise.reject(error);
  }
);



export default axios;
